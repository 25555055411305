<template>
	<div class="position-relative">
		<div
			class="position-absolute h-100 bg-white w-100"
			style="top: 0; left: 0">
			<div>
				<div
					class="row"
					:style="`height: ${backgroundHeight};`">
					<div
						:style="backgroundStyles"
						class="col-12" />
				</div>
				<div
					class="row"
					:style="`height: 100%; max-height: ${backgroundHeight} !important;`">
					<div class="col-12 bg-white" />
				</div>
			</div>
		</div>
		<div
			class="position-relative">
			<section
				:class="['xs', 'sm', 'md'].includes(windowWidth) ? '': 'p-5'"
				class="w-100 row no-gutters text-dark">
				<div class="col d-flex justify-content-center align-items-center">
					<div
						:class="['xs', 'sm', 'md'].includes(windowWidth) ? '': 'px-5'"
						class="container-xl mw-100">
						<h3
							:class="{
								'pt-5 mt-5': ['xs', 'sm'].includes(windowWidth),
								'pt-3 mt-5': windowWidth === 'md',
								...titleClasses,
							}"
							class="h1 text-center text-white font-weight-bolder">
							{{ translate('buy_now') }}
						</h3>
						<product-card
							modal-id="product-card-1"
							horizontal
							:highlight-color="highlightColor"
							:title="title"
							:product="product"
							:rounded-button="roundedButton" />
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import { Store } from '@/translations';
import Color from '../mixins/Color';
import WindowSizes from '@/mixins/WindowSizes';
import ProductCard from './ProductCard';

export default {
	name: 'BuySection',
	messages: [Store],
	components: {
		ProductCard,
	},
	mixins: [Color, WindowSizes],
	props: {
		background: {
			type: String,
			required: true,
		},
		backgroundHeight: {
			type: String,
			required: true,
		},
		product: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		titleClasses: {
			type: Object,
			default: () => ({}),
		},
		roundedButton: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		backgroundStyles() {
			let styles = [];
			if (this.background.includes('gradient')) {
				styles = [`background: ${this.background};`];
			} else if (this.background.startsWith('#')) {
				styles = [`background-color: ${this.background};`];
			} else {
				styles = [
					`background-image: url('${this.background}');`,
					'background-size: cover;',
					'background-position: center;',
					'background-repeat: none;',
				];
			}

			return styles.join(' ');
		},
	},
};
</script>
