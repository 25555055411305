<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		class="pt-3"
		style="background-color: white">
		<b-row
			no-gutters
			:class="['xs', 'sm'].includes(windowWidth) ? 'py-5' : ''"
			:style="containerStyle">
			<b-col
				v-if="!['xs', 'sm'].includes(windowWidth)"
				cols="3"
				offset="2">
				<div
					class="d-block w-100 h-100"
					:style="backgroundStyles" />
			</b-col>
			<b-col
				cols="12"
				md="6"
				class="d-flex mx-auto flex-column justify-content-center align-items-center">
				<div :style="`${title.color ? `color: ${title.color};` : makeHighlightStyle('color')} ${title.style}`">
					<span
						style="white-space: nowrap"
						class="h2 text-uppercase"
						:class="title.class">
						{{ title.text }}
					</span>
				</div>
				<div class="mt-2">
					<span
						:style="`${content.color ? `color: ${content.color};` : ''} ${content.style}`"
						:class="content.class"
						v-html="content.text" />
				</div>
				<div class="mt-4">
					<div id="email-button-tooltip">
						<button
							:class="{
								'w-100': ['xs', 'sm'].includes(windowWidth),
								'px-5': !['xs', 'sm'].includes(windowWidth),
								'bg-gray': loading || !email,
								'pointer': !loading && email,
							}"
							:disabled="!email"
							class="contact-button btn btn-lg text-white rounded-0 py-2"
							:style="email ? makeHighlightStyle('background-color') : ''"
							@click.prevent="email ? openMailer() : null">
							<div
								:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : 'mx-5'"
								class="font-weight-bolder h6 my-2 text-uppercase">
								<template v-if="!loading">
									{{ cta.text }}
								</template>
								<template v-else>
									<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('loading') }}
								</template>
							</div>
						</button>
					</div>
					<b-tooltip
						:disabled="loading || !!email"
						target="email-button-tooltip"
						triggers="hover">
						{{ translate('contact_not_available') }}
					</b-tooltip>
				</div>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import { LandingPages } from '@/translations';
import ReplicatedSite from '@/util/ReplicatedSite';
import Color from '../mixins/Color';

export default {
	name: 'ContactSection',
	messages: [LandingPages],
	mixins: [Color, WindowSizes],
	props: {
		email: {
			type: String,
			default: '',
		},
		loading: {
			type: Boolean,
			default: false,
		},
		image: {
			type: String,
			default: () => [],
		},
		title: {
			type: Object,
			default: () => ({}),
		},
		content: {
			type: Object,
			default: () => ({}),
		},
		cta: {
			type: Object,
			default: () => ({}),
		},
		containerStyle: {
			required: true,
			type: String,
			default: '',
		},
	},
	data() {
		return {
			replicated: new ReplicatedSite(),
		};
	},
	computed: {
		backgroundStyles() {
			const styles = [
				['xs', 'sm'].includes(this.windowWidth) ? 'height: 30vw;' : '',
				`background-image: url('${this.image}');`,
				'background-size: contain;',
				'background-position: center;',
				'background-repeat: no-repeat;',
			];

			return styles.join(' ');
		},
	},
	methods: {
		openMailer() {
			this.$emit('mail');
		},
	},
};
</script>
<style scoped>
.contact-button:focus {
	box-shadow: none;
}
</style>
