<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		:style="containerStyle">
		<b-row
			:class="{
				'mx-2 px-5': !['xs', 'sm'].includes(windowWidth),
				'mx-1': ['xs', 'sm'].includes(windowWidth),
				'h-100': true,
			}">
			<b-col
				lg="6"
				cols="12"
				:class="{
					'h-100': !['xs', 'sm', 'md'].includes(windowWidth),
				}"
				:order="!['xs', 'sm', 'md'].includes(windowWidth) && reversed ? 1 : 0">
				<img
					v-if="!media.clickable"
					class="embed-responsive"
					style="width: 100%; height: 100%; object-fit: contain;"
					:src="media.image"
					alt="image">
				<img
					v-else
					v-b-modal="media.mediaId"
					class="embed-responsive pointer"
					style="width: 100%; height: 100%; object-fit: contain;"
					:src="media.image"
					alt="preview image">
			</b-col>
			<b-col
				lg="6"
				cols="12"
				:class="{
					'h-100': !['xs', 'sm', 'md'].includes(windowWidth),
				}"
				class="d-flex flex-column justify-content-center">
				<b-row
					v-if="!!title"
					class="mb-4">
					<b-col>
						<div
							v-html="title" />
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<div
							v-html="content" />
					</b-col>
				</b-row>
				<b-row
					v-if="!!Object.keys(cta).length"
					class="mt-3"
					:class="['xs', 'sm'].includes(windowWidth) ? 'justify-content-center' : ''">
					<b-col>
						<a
							v-if="cta.url"
							:href="cta.url"
							:target="cta.target || '_self'"
							:class="`${['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''} ${cta.class}`"
							class="btn btn-lg text-white pointer rounded-0 py-1"
							:style="`${makeHighlightStyle('background-color')} ${cta.style}`">
							<div :class="ctaClasses">
								{{ cta.text }}
							</div>
						</a>
						<div
							v-else
							:class="`${['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''} ${cta.class}`"
							class="btn btn-lg text-white pointer rounded-0 py-1"
							:style="`${makeHighlightStyle('background-color')} ${cta.style}`"
							@click="handleClick(cta)">
							<div :class="ctaClasses">
								{{ cta.text }}
							</div>
						</div>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
		<b-modal
			v-if="media.video"
			:id="media.mediaId"
			modal-class="media-modal"
			hide-footer
			centered
			size="lg">
			<div>
				<b-embed
					type="iframe"
					aspect="16by9"
					:src="media.video[language]"
					allow="autoplay; fullscreen"
					allowfullscreen />
			</div>
		</b-modal>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import Color from '../mixins/Color';
import Scroll from '../mixins/Scroll';

export default {
	name: 'MediaSection',
	components: {},
	mixins: [Color, Scroll, WindowSizes],
	props: {
		media: {
			type: Object,
			required: true,
		},
		title: {
			type: String,
			default: '',
		},
		content: {
			type: String,
			default: '',
		},
		cta: {
			type: Object,
			default: () => ({}),
		},
		reversed: {
			type: Boolean,
			default: false,
		},
		containerStyle: {
			type: String,
			default: '',
		},
	},
	computed: {
		ctaClasses() {
			const classes = [
				'font-weight-bolder h6 my-2',
				['xs', 'sm'].includes(this.windowWidth) ? 'w-100' : 'mx-auto',
				'text-uppercase',
				'px-1',
			];

			return classes.join(' ');
		},
	},
	methods: {
		handleClick(cta) {
			if (cta.showModal) {
				this.$root.$emit('bv::show::modal', cta.showModal);
			} else if (cta.scrollTo) {
				this.scrollTo(cta.scrollTo);
			}
		},
	},
};
</script>
