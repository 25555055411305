<!-- eslint-disable vue/no-v-html -->
<template>
	<page-section :background-style="backgroundStyle ? backgroundStyle : ''">
		<div class="h-100 d-flex flex-column justify-content-center">
			<div
				v-if="!!title"
				class="text-center"
				v-html="title" />
			<div
				v-for="(iconRow, rowIndex) in iconRows"
				:key="`iconRow-${rowIndex}`"
				:class="rowIndex === iconRows.length - 1 ? '' : 'mb-5'"
				class="row justify-content-center">
				<div
					v-for="(icon, iconIndex) in iconRow"
					:key="`iconCol-${iconIndex}`"
					class="col d-flex flex-column align-items-center justify-content-around">
					<img
						class="img-fluid"
						style="max-height: 100px; !important"
						:src="icon.file">
					<span
						:class="smallerTexts && !['xs', 'sm'].includes(windowWidth) ? 'h6' : 'h5'"
						class="mt-3 text-center"
						style="max-width: 200px;"
						:style="`color: ${textColor}`">
						<div
							class="d-inline"
							v-html="icon.text" />
						<template
							v-if="includeAddendum">
							<sup
								class="font-weight-bold"
								:style="makeHighlightStyle('color')">‡</sup>
						</template>
					</span>
				</div>
			</div>
			<div
				v-if="!!disclaimer"
				class="mx-auto text-center mt-4 py-2 px-5 text-small"
				:style="!['xs', 'sm'].includes(windowWidth) ? 'width: 65%;' : 'width: 100%;'"
				style="background-color: rgba(255, 255, 255, 0.5)">
				<template
					v-if="includeAddendum">
					<sup
						class="font-weight-bold"
						:style="makeHighlightStyle('color')">‡</sup>
				</template>
				<span v-html="disclaimer" />
			</div>
		</div>
	</page-section>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import PageSection from './PageSection';
import Color from '../mixins/Color';

export default {
	name: 'IconsSection',
	components: {
		PageSection,
	},
	mixins: [Color, WindowSizes],
	props: {
		backgroundStyle: {
			type: String,
			default: '',
		},
		icons: {
			type: Array,
			required: true,
		},
		maxCols: {
			type: [String, Number],
			required: true,
		},
		includeAddendum: {
			type: Boolean,
			default: false,
		},
		smallerTexts: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: '',
		},
		disclaimer: {
			type: String,
			default: '',
		},
		textColor: {
			type: String,
			default: '',
		},
	},
	computed: {
		iconRows() {
			const { windowWidth } = this;
			const colsPerWindowWidth = {
				xs: 1,
				sm: 2,
				md: 2,
				lg: 3,
				xl: this.maxCols,
			};
			const rows = [];

			this.icons.forEach((icon) => {
				if (rows.length === 0 || rows[rows.length - 1].length >= colsPerWindowWidth[windowWidth]) {
					rows.push([]);
				}
				rows[rows.length - 1].push(icon);
			});
			return rows;
		},
	},
};
</script>
