var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(_vm.containerStyle)},[_c('b-row',{class:{
			'mx-2 px-5': !['xs', 'sm'].includes(_vm.windowWidth),
			'mx-1': ['xs', 'sm'].includes(_vm.windowWidth),
			'h-100': true,
		}},[_c('b-col',{class:{
				'h-100': !['xs', 'sm', 'md'].includes(_vm.windowWidth),
			},attrs:{"lg":"6","cols":"12","order":!['xs', 'sm', 'md'].includes(_vm.windowWidth) && _vm.reversed ? 1 : 0}},[(!_vm.media.clickable)?_c('img',{staticClass:"embed-responsive",staticStyle:{"width":"100%","height":"100%","object-fit":"contain"},attrs:{"src":_vm.media.image,"alt":"image"}}):_c('img',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(_vm.media.mediaId),expression:"media.mediaId"}],staticClass:"embed-responsive pointer",staticStyle:{"width":"100%","height":"100%","object-fit":"contain"},attrs:{"src":_vm.media.image,"alt":"preview image"}})]),_c('b-col',{staticClass:"d-flex flex-column justify-content-center",class:{
				'h-100': !['xs', 'sm', 'md'].includes(_vm.windowWidth),
			},attrs:{"lg":"6","cols":"12"}},[(!!_vm.title)?_c('b-row',{staticClass:"mb-4"},[_c('b-col',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.title)}})])],1):_vm._e(),_c('b-row',[_c('b-col',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.content)}})])],1),(!!Object.keys(_vm.cta).length)?_c('b-row',{staticClass:"mt-3",class:['xs', 'sm'].includes(_vm.windowWidth) ? 'justify-content-center' : ''},[_c('b-col',[(_vm.cta.url)?_c('a',{staticClass:"btn btn-lg text-white pointer rounded-0 py-1",class:((['xs', 'sm'].includes(_vm.windowWidth) ? 'w-100' : '') + " " + (_vm.cta.class)),style:(((_vm.makeHighlightStyle('background-color')) + " " + (_vm.cta.style))),attrs:{"href":_vm.cta.url,"target":_vm.cta.target || '_self'}},[_c('div',{class:_vm.ctaClasses},[_vm._v(" "+_vm._s(_vm.cta.text)+" ")])]):_c('div',{staticClass:"btn btn-lg text-white pointer rounded-0 py-1",class:((['xs', 'sm'].includes(_vm.windowWidth) ? 'w-100' : '') + " " + (_vm.cta.class)),style:(((_vm.makeHighlightStyle('background-color')) + " " + (_vm.cta.style))),on:{"click":function($event){return _vm.handleClick(_vm.cta)}}},[_c('div',{class:_vm.ctaClasses},[_vm._v(" "+_vm._s(_vm.cta.text)+" ")])])])],1):_vm._e()],1)],1),(_vm.media.video)?_c('b-modal',{attrs:{"id":_vm.media.mediaId,"modal-class":"media-modal","hide-footer":"","centered":"","size":"lg"}},[_c('div',[_c('b-embed',{attrs:{"type":"iframe","aspect":"16by9","src":_vm.media.video[_vm.language],"allow":"autoplay; fullscreen","allowfullscreen":""}})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }